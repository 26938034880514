import React from 'react';
import Bar from '../svgs/bar.svg';
import Door from '../svgs/door.svg';
import Gate from '../svgs/gate.svg';
import Window from '../svgs/window.svg';
import styles from './mainPage.module.scss';
import LogoGallery from './logoGallery';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

export const MainPage = () => {
  const data = useStaticQuery(graphql`
    {
        imagesJson(image: {name: {eq: "IMG_2083"}}) {
        image {
          childImageSharp {
            fluid(maxWidth: 4000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      allLogosJson {
        edges {
          node {
            alt
            title
            image {
              childImageSharp {
                fluid(maxWidth: 300, maxHeight: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `);
  const contents = [
    {
      Svg: Gate,
      header: 'Bramy ogrodzeniowe, przemysłowe, garażowe',
    },
    {
      Svg: Bar,
      header: 'Szlabany, blokady parkingowe',
    },
    {
      Svg: Door,
      header: 'Drzwi wewnętrzne i zewnętrzne',
    },
    {
      Svg: Window,
      header: 'Okna PCV i ALU'
    }
  ];
  return (
    <div className={styles.container}>
      <section className={styles.headerImage}>
        <span className={styles.opacity} />
        <Img fluid={data.imagesJson.image.childImageSharp.fluid} alt="brama wjazdowa" /><p>Na rynku od 12 lat!</p>
      </section>
      <section className={styles.aboutUs}>
        <p>
          Firma BRAMINSTAL zajmuje się produkcją i montażem bram wjazdowych przesuwnych i skrzydłowych, furtek, przęseł
          ogrodzeniowych. Sprzedażą i montażem bram garażowych segmentowych i uchylnych, napędów do bram i drzwi,
          szlabanów i systemów parkingowych.
        </p>
        <p>
          Obsługujemy klientów indywidualnych oraz firmy i instytucje. Zapewniamy wysoką jakość usług a także doradztwo
          techniczne poparte wieloletnim doświadczeniem.
        </p>
      </section>
      <section className={styles.blockSection}>
        <header className={styles.header}>Kompleksowa i fachowa usługa</header>
        <div className={styles.blockContainer}>
          <Block data={contents} />
        </div>
      </section>
      <section className={styles.blockSection}>
        <header className={styles.header}>Bogata oferta</header>
        <LogoGallery logoImages={data.allLogosJson.edges} />
      </section>
    </div>
  );
};

const Block = ({ data }) => {
  return data.map(({ Svg, header, content }) => (
    <div key={header} className={styles.block}>
      <Svg className={styles.svg} />
      <span className={styles.headline}>{header}</span>
      <span>{content}</span>
    </div>
  ));
};
