import React from 'react';
import 'leaflet/dist/leaflet.css';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import Image from '../components/image';
import SEO from '../components/seo';
import { MainPage } from '../components/mainPage';

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <MainPage />
  </Layout>
);

export default IndexPage;
