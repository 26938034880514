import React, { useState } from 'react';
import PrevArrow from '../svgs/back.svg';
import NextArrow from '../svgs/next.svg';
import styles from './logoGallery.module.scss';

const LogoGallery = ({ logoImages }) => {
  const [currPhoto, setCurrPhoto] = useState(0);
  return (
    <div className={styles.galleryContainer}>
      <span onClick={() => setCurrPhoto(currPhoto - 1 < 0 ? logoImages.length - 1 : currPhoto - 1)}>
        <PrevArrow />
      </span>
      <div className={styles.gallery}>
        <div className={styles.imageHolder}>
          {/*  <Image fixed={logoImages[currPhoto].node.image.childImageSharp.fixed} alt={logoImages[currPhoto].node.alt}/>*/}
          <img src={logoImages[currPhoto].node.image.childImageSharp.fluid.src} alt={logoImages[currPhoto].node.alt} />
        </div>
      </div>
      <span onClick={() => setCurrPhoto(currPhoto + 1 > logoImages.length - 1 ? 0 : currPhoto + 1)}>
        <NextArrow />
      </span>
    </div>
  );
};
{
  /*<img src={logoImages[currPhoto].node.image.childImageSharp.fluid.src} alt={logoImages[currPhoto].node.alt}/>*/
}

export default LogoGallery;
